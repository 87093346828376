<template>
    <div class="loan">
       <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack()">我要认证</div>
       <div class="loan-title">线上认证：</div>
       <router-link to="/realNameAuth" class="loan-card">
           <img src="@/assets/img/auth1.png" alt="">
           <div class="loan-title">企业基本信息 +<br/>法定代表人人脸识别</div>
       </router-link>
       <router-link to="/realNameAuthB" class="loan-card">
           <img src="@/assets/img/auth2.png" alt="">
           <div class="loan-title">企业基本信息 +<br/>法定代表人银行卡号</div>
       </router-link>
       <router-link to="/realNameAuthC" class="loan-card" v-if="!step3">
           <img src="@/assets/img/auth3.png" alt="">
           <div class="loan-title">企业基本信息 +<br/>企业小额打款</div>
       </router-link>
       <div class="loan-card" v-else @click="goStep3()">
           <img src="@/assets/img/auth3.png" alt="">
           <div class="loan-title loan-title2">企业基本信息 +<br/>企业小额打款</div>
           <div class="auth-btn">
                <div class="loan-btn">输入入账金额</div>
                <!-- <div class="loan-btn" @click="afresh()">重新认证</div> -->
            </div>
           <img src="@/assets/img/rz_clz.png" alt="" class="status">
       </div>
       <div class="loan-title">线下认证：</div>
       <router-link to="/realNameAuthD" class="loan-card">
           <img src="@/assets/img/auth4.png" alt="">
           <div class="loan-title loan-title2"><br/>企业线下认证</div>
       </router-link>
    </div>
</template>
<script>
import { useRouter } from 'vue-router'
import { ref,onMounted } from 'vue';
import { useStore } from 'vuex';
import {apiGxiAuth} from '../../api/axios';
export default {
    setup() {
        let router = useRouter();
        let store = useStore();
        const step3 = ref(false);
        if(store.state.token) {
        let time = Date.parse(new Date()).toString();
        //查询小额打款认证中
        apiGxiAuth({reqType: 'Q0201',timestap: time,qryType: '0'}).then(res => {          
              if(res.code == '1'){
                  if(res.info.info.AUTH_TYPE == '5' && res.info.info.STATUS == 'B'){
                      step3.value = true;
                  }
                }
                else {
                  console.log(res.msg);
                }
        });
      }

        onMounted(() => {
                localStorage.removeItem("setRealNameAuthLink");
        });
        const afresh = () =>{
                router.push({
                    path:'/realNameAuthC',
                });  
        };
        const goStep3 = () =>{
            if(step3.value) {
                router.push({
                    path:'/realNameAuthC',
                    query:{ num: 3},
                });  
            }                
        };
        const goBack = () =>{
            if(store.state.isiOS) {
            try {
                window.webkit.messageHandlers.back.postMessage(null);
            } catch (e) {
                alert('跳转返回ios失败');
            }
            }else {
                router.push({
                    name: 'User'
                })
            }
        };
        return {
            store,
            step3,
            goStep3,
            afresh,
            goBack
        }
        
        
    },

}
</script>
<style lang="less" scoped>
.loan {
    padding-top: 12.5vw;
    .loan-title {
        font-weight: bold;
        margin: 4vw 4vw 0 4vw;
        font-size: 5vw;
    }
    .loan-card {
        display: block;
        margin: 4vw;
        width: 92vw;
        height: 26vw;
        border-radius: 1.5vw;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
        }
        .loan-title {
            font-size: 5vw;
            font-weight: 600;
            color: #fff;
            margin-left: 35vw;
            padding-top: 5vw;
            line-height: 8vw;
        }
        .loan-title2{
            padding-top: 2vw;
        }
        .auth-btn {
            margin-left: 35vw;
            .loan-btn {
            font-size: 4vw;
            color: #fff;
            display: inline-block;
            padding: 0 2vw;
            border: 1px solid #fff;
            border-radius: 4vw;
            // margin-top: 2vw;
            }
            .loan-btn:first-child {
            margin-right: 3vw;
            }

        }
        
        img.status {
            width: 13.6vw;
            height: auto;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}
</style>